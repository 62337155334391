<template>
  <v-hover v-slot="{ hover }">
    <v-sheet
      block
      :height="height"
      :elevation="hover && status != 'not_available' ? 12 : 0"
      :class="`d-flex justify-center align-center cursorstyle ${status} ${
        hover && status != 'not_available' ? 'hoverstyle' : ''
      }`"
      @mouseover="highlight"
      @mouseout="removeHighlight"
    >
      <div
        v-if="
          status == 'maintenance' || status == 'event' || status == 'dependency'
        "
      >
        <span v-html="name"></span>
      </div>

      <v-row v-else-if="status != 'not_available'" justify="center">
        <v-col
          md="4"
          sm="6"
          @click="onClick('add')"
          v-if="
            isEnablePerDayCapacity && !splitCapacity
              ? perDayCapacity > (totalAttendance+reservedQuantity) && !isPast
              : (bookings + reservedQuantity) < capacity && !isPast && !splitCapacity && checkWritePermission($modules.schedule.management.slug)
          "
        >
          <v-btn color="teal" dark fab x-small>
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col md="4" sm="6" v-if="(bookings + reservedQuantity) > capacity && isPast"> </v-col>
        <v-col
          :md="
            isEnablePerDayCapacity &&
            totalAttendance >= perDayCapacity &&
            !isPast
              ? 12
              : (bookings + reservedQuantity) < capacity && !isPast && !splitCapacity
              ? 8
              : 12
          "
          :sm="(bookings + reservedQuantity) < capacity ? 6 : 12"
          class="pr-8 mx-auto"
          @click="onClick('participant')"
          v-if="status == 'unpaid'"
          :class="{ status }"
        >
          <v-btn color="white" x-small dark outlined block
            >{{ (bookings + reservedQuantity) }}
            <span v-if="!isEnablePerDayCapacity">/{{ capacity }}</span>
          </v-btn>
        </v-col>
        <v-col
          :md="
            isEnablePerDayCapacity &&
            totalAttendance >= perDayCapacity &&
            !isPast
              ? 12
              : (bookings + reservedQuantity) < capacity && !isPast
              ? 8
              : 12
          "
          :sm="(bookings + reservedQuantity) < capacity ? 6 : 12"
          class="pr-8"
          @click="onClick('participant')"
          v-else
          :class="{ status }"
        >
          <v-btn color="teal" x-small dark outlined block
            >{{ (bookings + reservedQuantity)
            }}<span v-if="!isEnablePerDayCapacity">/{{ capacity }}</span></v-btn
          >
        </v-col>
      </v-row>
      <v-row v-else-if="status == 'not_available'">
        <v-col md="12" class="text-center text-white">
          <span>{{ name }}</span>
        </v-col>
      </v-row>
    </v-sheet>
  </v-hover>
</template>

<script>
export default {
  props: {
    id: { type: Number },
    status: { type: String, default: "available" },
    draggable: { type: Boolean, default: false },
    height: { type: Number, default: 50 },
    index: { type: Number, default: 0 },
    bookings: { type: Number, default: 0 },
    capacity: { type: Number, default: 0 },
    name: { type: String, default: "" },
    isPast: { type: Boolean, default: false },
    creditOwed: { type: Number, default: 0 },
    perDayCapacity: { type: Number, default: 0 },
    isEnablePerDayCapacity: { type: Number, default: 0 },
    totalAttendance: { type: Number, default: 0 },
    splitCapacity: { type: Boolean, default: false },
    reservedQuantity: { type: Number, default: 0 },
  },
  methods: {
    onClick(type) {
      if (this.status != "not_available" && this.status != "maintenance") {
        if (type == "add") {
          this.$emit("click");
        } else {
          this.$emit("participants");
        }
      }
    },
    highlight() {
      this.$store.dispatch("highlightTime", this.index);
    },
    removeHighlight() {
      this.$store.dispatch("removeHighlightTime", this.index);
    },
  },
};
</script>

<style scoped lang="scss">
.booked {
  background: rgb(0, 89, 118) !important;
  color: #fff;
  cursor: pointer;
}
.available {
  cursor: pointer;
}
.not_available {
  cursor: not-allowed;
  background: rgb(176, 191, 196) !important;
}
.hoverstyle {
  border: 2px solid teal;
}
.v-btn {
  margin: 6px 8px;
  width: 20px;
  height: 20px;
}
.maintenance {
  background: rgb(150, 26, 4) !important;
  color: #fff;
  cursor: not-allowed;
}
.trainer {
  background: rgb(21, 114, 2) !important;
  color: #fff;
  cursor: pointer;
}
.completed {
  background: #e0f4f4;
}

.workshop {
  color: rgb(7, 0, 0);
  cursor: pointer;
}
.event {
  background: #91a0b4 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
  color: #e0f1f4;
}
.dependency {
  background: #a79493 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
  color: #fcf0f0;
}
.unpaid {
  background: rgb(206, 168, 0) !important;
  color: #fff;
  cursor: pointer;
}
.paid {
  background: rgb(0, 89, 118) !important;
  color: #fff;
  cursor: pointer;
}
.paid button {
  color: #fff !important;
}
.unapproved {
  background-color: #76510d91 !important;
  color: #fff !important;
  cursor: pointer;
}
.unapproved1 {
  background-color: #ff990091 !important;
  color: #fff !important;
  cursor: pointer;
}
.cart {
  background-color: #FF6F61 !important;
  color: #fff !important;
  cursor: pointer;
  .v-btn{
    color: #fff !important;
  }
}
</style>
