<template>
  <v-hover v-slot="{ hover }">
    <v-sheet
      block
      :height="height"
      @click="onClick"
      :elevation="hover && status != 'not_available' ? 12 : 0"
      :class="
        `d-flex justify-center align-center slot-pos cursorstyle ${status} ${
          hover && ['paid', 'unpaid', 'trainer'].includes(status)
            ? 'hoverstyle'
            : ''
        }`
      "
      :style="{ background: color, overflow: 'hidden' }"
      @mouseover="highlight"
      @mouseout="removeHighlight"
    >
      <div v-if="repeat" :style="{ 'z-index': fillHeight > 0 ? 9 : 0 }">
        <v-icon color="white"> mdi-repeat-variant </v-icon>
      </div>
      <template slot></template>
      <v-btn
        color="teal"
        @click="onClick"
        fab
        x-small
        dark
        v-if="status == 'available' && checkWritePermission($modules.schedule.management.slug)"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
      <div v-else :style="{ 'z-index': fillHeight > 0 ? 9 : 0 }">
        <span v-html="name"></span>
        <span v-if="isOverNight" style="display:block">(Extended)</span>

      </div>
      <div class="credit-owed" v-if="creditOwed">
        <span>c</span>
      </div>
      <div
        v-if="fillHeight > 0"
        class="slot-pre-fill"
        :style="{ height: fillHeight + 'px' }"
      ></div>
    </v-sheet>
  </v-hover>
</template>

<script>
export default {
  props: {
    id: { type: Number },
    status: { type: String, default: "available" },
    draggable: { type: Boolean, default: false },
    height: { type: Number, default: 50 },
    fillHeight: { type: Number, default: 0 },
    name: { type: String, default: null },
    events: { type: Object, default: null },
    color: { type: String },
    index: { type: Number, default: 0 },
    repeat: { type: Boolean, default: false },
    creditOwed: { type: Number, default: 0 },
    isOverNight: { type: Boolean, default: false},
  },
  data() {
    return {
      textColor: "blue",
    };
  },
  methods: {
    onClick() {
      if (!this.checkWritePermission(this.$modules.schedule.management.slug) && !this.id){
        return;
      }
      if (this.status === 'cart'){
        return;
      }
      if (
        this.status != "not_available" &&
        this.status != "maintenance" &&
        this.status != "event" &&
        this.status != "completed" &&
        this.status != "dependency"
      ) {
        if(!this.isOverNight){
          this.$emit("click");
        }else{
          this.showInfo("This is extended booking, If you want to view, Please click on original booking");
          return;
        }
      }
    },
    highlight() {
      this.$store.dispatch("highlightTime", this.index);
    },
    removeHighlight() {
      this.$store.dispatch("removeHighlightTime", this.index);
    },
  },
};
</script>

<style scoped>
.paid {
  background: rgb(0, 89, 118) !important;
  color: #fff;
  cursor: pointer;
}
.unpaid {
  background: rgb(206, 168, 0) !important;
  color: #fff;
  cursor: pointer;
}
.available {
  cursor: pointer;
}
.not_available {
  cursor: not-allowed;
  background: #bfbfbf !important;
}
.hoverstyle {
  /* border: 2px solid teal; */
}
.available .v-btn {
  margin: 6px 8px;
  width: 20px;
  height: 20px;
}
.maintenance {
  background: rgb(150, 26, 4) !important;
  color: #fff;
  cursor: not-allowed;
}
.trainer {
  background: rgb(21, 114, 2) !important;
  color: #fff;
  cursor: pointer;
}
.completed {
  background: #e0f4f4;
}

.workshop {
  color: rgb(7, 0, 0);
  cursor: pointer;
}
.event {
  background: #91a0b4 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
  color: #e0f1f4;
}
.dependency {
  background: #a79493 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
  color: #fcf0f0;
}
.credit-owed {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  background: #ffc000;
  color: #fff;
  border-radius: 50%;
  height: 18px;
  z-index: 0;
}
.unapproved {
  background-color: #76510d91;
  color: #fff !important;
  cursor: pointer;
}

.unapproved1 {
  background-color: #ff990091 !important;
  color: #fff !important;
  cursor: pointer;
}

.cart {
  background-color: #FF6F61 !important;
  color: #fff !important;
  cursor: pointer;
  .v-btn{
    color: #fff !important;
  }
}
</style>
